(function () {
  var links = [...document.querySelectorAll(".nav-link")];
  var mainPageContentBlocks = ["#main", "#services", "#contacts"].map(id => document.querySelector(id));
  console.log(mainPageContentBlocks);

  function setClasses(activeLinkHash, displayedBlock) {
    links.forEach(link => link.classList.toggle("active", link.getAttribute("href") === activeLinkHash));
    mainPageContentBlocks.forEach(block => block.classList.toggle("displayed", block.id === displayedBlock));
  }

  function loadContent() {
    var hash = (window.location.hash || "").substring(1);

    switch (hash) {
      case "services":
        setClasses("/#services", "services");
        break;
      case "contacts":
        setClasses("/#contacts", "contacts");
        break;
      case "":
      default:
        setClasses("/#", "main");
    }
  }

  window.onhashchange = loadContent;
  loadContent();
})();
